<template>
  <div id="item-detail-page" class="shop-wrapper">
    <vs-alert
      color="danger"
      title="Error Fetching Product Data"
      :active.sync="error_occured"
    >
      <span>{{ error_msg }}. </span>
      <span>
        <span>Check </span
        ><router-link
          :to="{ name: 'ecommerce-shop' }"
          class="text-inherit underline"
          >All Items</router-link
        >
      </span>
    </vs-alert>

    <vx-card v-if="productData" :key="productData._id">
      <template slot="no-body">
        <div class="item-content">
          <!-- Item Main Info -->
          <div class="product-details p-6">
            <div class="vx-row mt-6">
              <div
                class="vx-col md:w-2/5 w-full flex items-start justify-center"
              >
                <Carousel :images="productData.images"></Carousel>
              </div>

              <!-- Item Content -->
              <div class="vx-col md:w-3/5 w-full">
                <div class="w-full lg:w-2/5 flex flex-col justify-center lg:pl-5 mb-5">
                  <div class="text-lg opacity-75 hidden">
                    {{ productData.productType }}
                  </div>
                  <div class="text-lg lg:text-2xl md:text-2xl font-semibold cursor-pointer">
                    {{ productData.name }}
                  </div>
                  <div class="text-sm opacity-75 hidden md:inline-block lg:inline-block my-1">
                    {{ productData.name }}
                  </div>
                  <div class="text-2xl font-semibold text-primary my-1">
                    <!-- ${{ productData.price | formatDecimals }} -->
                    ${{ hasDiscount(productData._id) ? hasDiscount(productData._id) : productData.price | formatDecimals }}
                  </div>
                </div>

                <div class="bg-white fixed left-0 md:left-none lg:left-none bottom-0 w-full lg:w-auto md:w-auto lg:bottom-none md:bottom-none lg:relative md:relative flex flex-row lg:pl-5 justify-between lg:justify-start md:justify-start">
                  <div v-if="productData.canBuy && productData.isAvailable" class="flex flex-row increment-buttons w-1/2 lg:w-32 md:w-32">
                    <div
                      class="
                        w-1/3
                        flex
                        justify-center
                        items-center
                        text-4xl text-primary
                        cursor-pointer
                      "
                      @click="
                        decrease(
                          quantity,
                          productData,
                          productData.inventory.requiredQuantity
                        )
                      "
                    >
                      <div>-</div>
                    </div>
                    <div class="w-1/3 flex justify-center items-center text-lg">
                      <div>{{ quantity }}</div>
                    </div>
                    <div
                      class="
                        w-1/3
                        flex
                        justify-center
                        items-center
                        text-4xl text-primary
                        cursor-pointer
                      "
                      @click="increase(quantity, productData)"
                    >
                      <div>+</div>
                    </div>
                  </div>

                  <div v-if="productData.canBuy && productData.isAvailable" class="flex flex-wrap items-start lg:mb-4 w-1/2 lg:w-auto md:w-auto">
                    <!-- Add To Cart Button -->
                    <vs-button
                      class="
                        md:ml-2
                        lg:ml-2
                        item-view-secondary-action-btn
                        bg-primary
                        py-4
                        md:py-3
                        lg:py-3
                        px-4
                        lg:rounded
                        items-center
                        justify-center
                        text-white
                        cursor-pointer
                        w-full
                      "
                      :disabled="!validateForm"
                      @click="toggleItemInCart(productData)"
                      
                    >
                      Add to Cart
                    </vs-button>
                    <!-- popup -->
                    <vs-popup
                      class="holamundo"
                      title="Supplier Account Required"
                      :active.sync="popupActive"
                    >
                      <div class="flex flex-no-wrap">
                        <div class="w-1/6 flex items-center justify-center">
                          <vs-icon
                            icon="clear"
                            size="medium"
                            bg="#f9d6d6"
                            color="#b21f1e"
                            round
                          ></vs-icon>
                        </div>
                        <div class="w-5/6">
                          <ContactFresh />
                        </div>
                      </div>
                      <div class="flex justify-end mt-5">
                        <vs-button
                          class="mr-4 mb-4"
                          @click="popupActive = false"
                        >
                          I understand
                        </vs-button>
                      </div>
                    </vs-popup>
                  </div>
                  <div v-if="!productData.canBuy" class="flex flex-wrap items-start lg:mb-4 w-full lg:w-auto md:w-auto">
                    <vs-button
                      class="
                        item-view-secondary-action-btn
                        bg-primary
                        py-5
                        lg:py-3
                        md:py-3
                        px-4
                        rounded
                        w-full
                        lg:w-auto
                        items-center
                        justify-center
                        text-white
                        cursor-pointer
                      "
                      @click="popupActive = true"
                    >
                      Contract Fresh
                    </vs-button>
                  </div>
                  <div v-if="!productData.isAvailable" class="flex flex-wrap items-start lg:mb-4 w-full lg:w-auto md:w-auto">
                    <vs-button
                      class="
                        item-view-secondary-action-btn
                        bg-gray-400
                        py-5
                        lg:py-3
                        md:py-3
                        px-4
                        rounded
                        w-full
                        lg:w-auto
                        items-center
                        justify-center
                        text-white
                        cursor-pointer
                      "
                    >
                      Out of Stock
                    </vs-button>
                  </div>
                </div>
                <div class="mt-5 lg:pl-5">
                  <h4 class="py-5 text-lg lg:text-2xl md:text-2xl">Description</h4>
                  <p v-for="(paragraph, index) in productData.description.split('\n')" v-bind:key="index" class="mb-5">{{paragraph}}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
    </vx-card>
  </div>
</template>

<script>
import "swiper/dist/css/swiper.min.css";
import { swiper, swiperSlide } from "vue-awesome-swiper";
import StarRating from "vue-star-rating";
import algoliasearch from "algoliasearch/lite";
import { mapActions } from "vuex";
import RelatedProducts from "../../components/order-center/RelatedProducts";
import Carousel from "./Carousel";
import ContactFresh from "../../components/ContactFresh";

export default {
  name: "ProductDetail",
  components: {
    Carousel,
    RelatedProducts,
    StarRating,
    ContactFresh
  },
  watch: {
    "$route.params.id": function (id) {
      this.fetch_item_details(id);
    },
  },
  data() {
    return {
      popupActive: false,
      quantity: 1,
      productData: null,
      availableQuantity: 0,
      error_occured: false,
      error_msg: "",
      // Below is data which is common in any item
      // Algolia's dataSet don't provide this kind of data. So, here's dummy data for demo
      available_item_colors: [
        "#7367F0",
        "#28C76F",
        "#EA5455",
        "#FF9F43",
        "#1E1E1E",
      ],
      opt_color: "#7367F0",
      is_hearted: false,
      relatedProducts: [],
      discounts: [],
      clinic: null
    };
  },
  computed: {
    item_qty() {
      const item = this.$store.getters["eCommerce/getCartItem"](
        this.productData.objectID
      );
      return Object.keys(item).length ? item.quantity : 1;
    },
    totalItemQuantityIncart() {
      return (itemId) =>
        this.$store.getters["eCommerce/totalItemQuantityIncart"](itemId);
    },
    itemColor() {
      return (obj) => {
        let style_obj = {};

        obj.style.forEach((p) => {
          style_obj[p] = obj.color;
        });

        return style_obj;
      };
    },
    isInWishList() {
      return (itemId) => this.$store.getters["eCommerce/isInWishList"](itemId);
    },
    isInCart() {
      return (itemId) => this.$store.getters["eCommerce/isInCart"](itemId);
    },
    validateForm() {
      return !this.errors.any();
    },
  },
  methods: {
    ...mapActions("ecommerce", ["fetchProductDetail"]),
    ...mapActions("discount", ["fetchDiscountList"]),
    ...mapActions("clinic", ["fetchClinicsDetail"]),


    hasDiscount(productId) {
      const clinicData = this.clinic
     
      if(clinicData && clinicData.data && clinicData.data.data && clinicData.data.data.clinicDiscounts.length > 0) {
        for(let discount of this.discounts) {
          if(clinicData.data.data.clinicDiscounts.includes(discount._id)) {
            for(let productDiscount of discount.productDiscounts)
              if (productDiscount.productStoreId === productId && productDiscount.isActive) {
                return Number(productDiscount.adjustedPrice).toFixed(2);
              } 
          }
        }
      }

      return null;

    },
    increase(quantity, data) {
      this.quantity = quantity + 1;
      this.selectedChangeQuantity(this.quantity, data);
    },
    decrease(quantity, data, requiredQuantity) {
      if (quantity - 1 >= requiredQuantity) {
        this.quantity = quantity - 1;
        this.selectedChangeQuantity(this.quantity, data);
      }
    },
    toggleItemInWishList(item) {
      this.$store.dispatch("eCommerce/toggleItemInWishList", item);
    },
    additemInCart(item) {
      this.$vs.loading();
      this.$store.dispatch("eCommerce/additemInCart", item).then((response) => {
        if(response.status === 200 && response.data.message === 'success') {
          this.$vs.notify({
            color: 'success',
            title: 'Product added',
            text: 'Product was successfully added to your cart.'
          })
        } else {
          this.$vs.notify({
            color: 'danger',
            title: `Product Not Updated`,
            text: `We are having issues updating your cart. Please try again.`
          })
        }
        
        this.$vs.loading.close();
      });
    },
    async toggleItemInCart(item) {
      let isValid = await this.$validator.validateAll("basic");
      if (!isValid) {
        this.$vs.notify({
          title: "Error",
          text: "Validation Failed",
          iconPack: "feather",
          icon: "icon-alert-circle",
          color: "danger",
        });
        return false;
      }
      const cartQuantity = this.totalItemQuantityIncart(item._id);

      if (this.quantity == 0) {
        this.$vs.notify({
          title: "Failed",
          text: "Quantity cannot be zero",
          color: "danger",
        });
        return false;
      }
      if (item.isAvailable !== true) {
        this.$vs.notify({
          title: "Failed",
          text: "Product is not available right now.",
          color: "danger",
        });
        return false;
      }

      item.selectedQuantity = this.quantity;
      item.shipping.shippingFee = item.supplier.deliveryDetails
        ? item.supplier.deliveryDetails.deliveryFee
        : 0;
      // if(item.selectedQuantity > item.quantity){
      //   this.$vs.notify({
      //     title: "Failed",
      //     text: 'Selected quantity is greater than available quantity',
      //     color: "danger",
      //   });
      //   return false;
      // }
      // if((cartQuantity +item.selectedQuantity) > item.quantity){
      //   this.$vs.notify({
      //     title: "Failed",
      //     text: 'Your cart exceeds the quantity of this product.',
      //     color: "danger",
      //   });
      //   return false;
      // }
      this.additemInCart(item);
      // this.$store.dispatch('eCommerce/toggleItemInCart', item)
    },
    fetch_item_details(id) {
      this.fetchProductDetail(this.$route.params.id).then(async (response) => {

        await this.fetchDiscountList().then((response) => {
          this.discounts = response.data.data;
        });
        
        this.productData = response.data.data.product;
        if (
          this.productData.inventory.requiredQuantity !== undefined &&
          this.productData.inventory.requiredQuantity !== 0
        ) {
          this.quantity = this.productData.inventory.requiredQuantity;
        } else {
          this.quantity = 1;
          this.productData.inventory.requiredQuantity = 1;
        }
        this.availableQuantity = response.data.data.product.quantity;
        this.relatedProducts = response.data.data.relatedProducts.docs;
      });
    },
    checkItemQuantity(quantity, data) {
      if (this.quantity < data.inventory.requiredQuantity) {
        this.errors.add({
          field: "MinimumQuantityLimit",
          msg:
            "Minimum order quantity of " +
            data.name +
            " is " +
            data.inventory.requiredQuantity,
          id: data._id,
        });
      } else {
        this.errors.remove("MinimumQuantityLimit");
      }
    },
  },
  async created() {
    this.fetch_item_details(this.$route.params.id);
    this.clinic = await this.fetchClinicsDetail(sessionStorage.getItem("doctorClinicSelectedClinic"));
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/vuexy/_variables.scss";

#item-detail-page {
  .increment-buttons {
    height: 40px;
    background: #3cc9b230;
    width: 100px;
    border-radius: 5px;
  }

  button.bg-gray {
    background-color: #a0a0a0!important;
  }

  .color-radio {
    height: 2.28rem;
    width: 2.28rem;

    > div {
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  .item-features {
    background-color: #f7f7f7;

    .theme-dark & {
      background-color: $theme-dark-secondary-bg;
    }
  }

  .product-sales-meta-list {
    .vs-list--icon {
      padding-left: 0;
    }
  }

  .related-product-swiper {
    // padding-right: 2rem;
    // padding-left: 2rem;

    .swiper-wrapper {
      padding-bottom: 2rem;

      > .swiper-slide {
        background-color: #f7f7f7;
        box-shadow: 0 4px 18px 0 rgba(0, 0, 0, 0.1),
          0 5px 12px 0 rgba(0, 0, 0, 0.08) !important;

        .theme-dark & {
          background-color: $theme-light-dark-bg;
        }
      }
    }

    .swiper-button-next,
    .swiper-button-prev {
      transform: scale(0.5);
      filter: hue-rotate(40deg);
    }

    .swiper-button-next {
      right: 0;
    }

    .swiper-button-prev {
      left: 0;
    }
  }
}
@media (max-width: 375px) {
  .vs-button {
    border-radius: 0!important;
  }
  .increment-buttons {
    height: auto!important;
    border-radius: 0!important;
  }
  .vue-back-to-top {
    bottom: 10%!important;
  }
}
</style>
