<template>
  <div ref="swiperBox"  class="swiperBox">
    <!-- swiper -->
    <swiper :options="swiperOption" :dir="$vs.rtl ? 'rtl' : 'ltr'" :key="$vs.rtl" >
      <swiper-slide v-for="sliderImage in images" :key="sliderImage._id" >

        <!-- Item Heading -->

        <!-- Item Image -->
<!--        <div class="img-container w-30 mx-auto my-base">-->
<!--          <img width ="380" height="auto" :src="sliderImage.image" :alt="sliderImage.name" style="margin-left: auto;-->
<!--  margin-right: auto;">-->
          <img width ="80%" height="auto" :src="sliderImage.image" :alt="sliderImage.name" style="margin-left: auto;
  margin-right: auto;">
<!--        </div>-->

        <!-- Item Meta -->

      </swiper-slide>
      <!-- <div class="swiper-button-prev" slot="button-prev"></div>
      <div class="swiper-button-next" slot="button-next"></div> -->
    </swiper>
  </div>

</template>

<script>
  import 'swiper/dist/css/swiper.min.css'
  import { swiper, swiperSlide } from 'vue-awesome-swiper'

  export default{
    name:"Carousel",
    props:['images'],
    components: {
      swiper,
      swiperSlide
    },
    data(){
      return {
        // Related Products Swiper
        swiperOption: {
          slidesPerView: 1,
          spaceBetween: 55,
          breakpoints: {
            1600: {
              slidesPerView: 1,
              // spaceBetween: 55,
            },
            1300: {
              slidesPerView: 1,
              spaceBetween: 55,
            },
            900: {
              slidesPerView: 1,
              spaceBetween: 55
            },
            640: {
              slidesPerView: 1,
              spaceBetween: 55
            }
          },
          navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev'
          }
        },
      }
    },
  }
</script>

<style scoped>
  .swiperBox{
    width:100%;
    position: relative;
  }
  .swiper_btn{
    position: absolute;
  }
  /*.swiper-button-prev{*/
  /*  width: 0.36rem;*/
  /*  height: 0.28rem;*/
  /*  !*background: url("path") no-repeat;*!*/
  /*  background-size: 100% 100%;*/
  /*}*/
  /*.swiper-button-next{*/
  /*  width: 0.36rem;*/
  /*  height: 0.28rem;*/
  /*  !*background: url("path") no-repeat;*!*/
  /*  background-size: 100% 100%;*/
  /*}*/
</style>
